<template>
    <div class="container">
        <div class="text-center mt-4 mb-4">
            <h4>Дисциплины</h4>
        </div>

        <div class="mt-3 mb-3" v-if="selectableDisciplines_form.disciplines.length">

<!--          <div v-for="(item, index) in selectableDisciplines_form.disciplines" :key="index">-->
<!--            <Card style="width: 25rem; margin-bottom: 2em">-->
<!--              <template #title>-->
<!--                {{item.education_discipline_name}}-->
<!--              </template>-->
<!--              <template #content>-->
<!--                <router-link-->
<!--                    :to="`/selectable-groups-for-discipline?education_discipline_id=${item.education_discipline_id}`">-->
<!--                  {{item.education_discipline_name}}-->
<!--                </router-link>-->
<!--              </template>-->
<!--            </Card>-->
<!--          </div>-->

          <div v-for="(item, index) in selectableDisciplines_form.disciplines" :key="index">
            <div class="card mt-4">
              <div class="card-body">

               <p class="card-text"> <b>Название дисциплины:</b></p>
                <p class="card-text">{{item.education_discipline_name}}</p>

                <div class="row">
                  <div class="col-md-3">
                    <router-link
                        :to="`/selectable-groups-for-discipline?education_discipline_id=${item.education_discipline_id}`">
                      Перейти к выбору группы
                    </router-link>
                  </div>
                </div>

              </div>
            </div>
          </div>




<!--            <DataTable :value="selectableDisciplines_form.disciplines" responsiveLayout="scroll">-->
<!--                <Column header="Название дисциплины">-->
<!--                    <template #body="{data}">-->
<!--                        <router-link-->
<!--                                :to="`/selectable-groups-for-discipline?education_discipline_id=${data.education_discipline_id}`">-->
<!--                            {{data.education_discipline_name}}-->
<!--                        </router-link>-->
<!--                    </template>-->
<!--                </Column>-->
<!--&lt;!&ndash;                <Column header="Преподаватель">&ndash;&gt;-->
<!--&lt;!&ndash;                    <template #body="{data}">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{data.lecture_pps_id ? data.lecture_pps_id : 'Пусто' }}&ndash;&gt;-->
<!--&lt;!&ndash;                    </template>&ndash;&gt;-->
<!--&lt;!&ndash;                </Column>&ndash;&gt;-->

<!--            </DataTable>-->
        </div>


    </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

    export default {
        name: "SelectableDisciplines",
        components: {
            // Paginate,
            // DeletePassportEducationProgramModal,
            // AddPassportEducationProgramModal
        },

        data() {
            return {
                education_discipline_id: +this.$route.query.formation_education_program_id || 0
            }
        },
        computed: {
            ...mapState('selectableDisciplines', ['selectableDisciplines_form'])
        },

        methods: {
            ...mapActions('selectableDisciplines', ['GET_LANGUAGE_DISCIPLINE_BY_STUDENT_ID']),
            // ...mapActions('educationprogram', ['POST_EDUCATION_PROGRAM_DATA', 'PUT_EDUCATION_PROGRAM_DATA', 'GET_EDUCATION_PROGRAM_DATA',
            //     'GET_EDUCATION_FIELD_NAMES', 'GET_EDUCATION_DIRECTION_NAMES', 'GET_EDUCATION_GROUPS_NAMES',
            //     'GET_STUDY_FORM_NAMES', 'GET_STUDY_LEVEL_NAMES']),
            // ...mapMutations('educationprogram', ['UPDATE_EDUCATION_PROGRAM_DATA', 'SET_UPDATE_ID', 'SET_DELETE_EDUCATION_PROGRAM_ID',
            //     'SET_DUPLICATE_EDUCATION_PROGRAM_ID']),
            // async duplicateEducationProgram() {
            //     console.log(this.checkedAdmissionYears, 'checkedAdmissionYears')
            //     console.log(this.educationprogram_form.duplicateId, 'duplicateId')
            //     const educationProgramData = {...this.educationprogram_form.educationProgramInfos.find(i => i.id == this.educationprogram_form.duplicateId)}
            //
            //     delete educationProgramData.id
            //     delete educationProgramData.decreeGosoTemplate
            //     delete educationProgramData.language
            //
            //     const educationPrograms = []
            //     this.checkedAdmissionYears.forEach(year => {
            //         educationPrograms.push({...educationProgramData, admission_year: year})
            //     })
            //
            //     if (educationPrograms.length) {
            //         this.POST_EDUCATION_PROGRAM_DATA(educationPrograms).then(res => {
            //             if (res.success == true) {
            //                 this.GET_EDUCATION_PROGRAM_DATA(this.page);
            //                 this.$message({title: 'Дублирование', text: 'Данные успешно дублировались'})
            //             } else {
            //                 const errorText = res.errors[0].map(err => err.message).join('\n');
            //                 this.$error({title: 'Дублирование', text: errorText})
            //             }
            //         })
            //     }
            //
            //
            // },
        },
        async mounted() {
            await this.GET_LANGUAGE_DISCIPLINE_BY_STUDENT_ID();
        }
    }
</script>

<style scoped>

</style>